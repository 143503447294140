.label-input {
    color: var(--Gray-CAEQ, #4d585e);
    font-feature-settings: "clig" off, "liga" off;
  
    /* text Input CAEQ */
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 1.68px;
    padding-left: 20px;
    margin-bottom: 10px;
    width: 100%;
}

.box-input {
    font-family: Montserrat;
    font-size: 18px;
    border-radius: 20px;
    box-shadow: 4px 7px 15px 0px rgba(0, 0, 0, 0.35);
    width: 100%;
    height: 40px;
    padding-left: 20px;
    border: none;
}