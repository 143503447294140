.principal-card {
    width: 100%;
    min-width: 100px;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
    padding: 1rem;
    background: #eff9f0;
    transition: transform 0.3s ease;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    img {
        margin-top: 10px;
        width: 60px;
        height: 60px;
    }
    p {
        padding: 10px;
        font-size: 16px;
        margin-top: 10px;
        text-align: center;
        font-weight: 600;
        word-break: keep-all;
    }
    h1 {
        margin-bottom: 0px;
    }

    @media screen and (max-width: 1440px) {
        // Media query for screens smaller than 768px (phones)
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        padding: 0px;
        p {
            font-size: 17px;
        }
        h1 {
            font-size: 20px;
        }
    }
    @media screen and (max-width: 1024px) {
        // Media query for screens smaller than 768px (phones)
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        padding: 0px;
        p {
            font-size: 14px;
        }
    }

    @media screen and (max-width: 435px) {
        width: fit-content;

        p {
            font-size: 12px;
        }
    }
}

.principal-card:hover {
    background-color: #eea300;
    transform: translateY(-15px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    color: #ffffff;

    img {
        width: 60px;
    }

    .image-profile {
        content: url('../../components/icons/ProfileIconWhite.svg');
    }
    .image-asambleas {
        content: url('../../components/icons/AsambleasIconWhite.svg');
    }
    .image-cursos {
        content: url('../../components/icons/CursosIconWhite.svg');
    }
    .image-servicios {
        content: url('../../components/icons/ServicesIconWhite.svg');
    }
    .image-beneficios {
        content: url('../../components/icons/BenefitsIconWhite.png');
        img {
            width: 100%;
        }
    }
}
