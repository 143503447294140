.profile {
    color: #000;
    text-align: center;

    font-family: Montserrat;
    font-style: normal;
    line-height: normal;
    display: flex;
    flex-direction: column;
    width: 100%;

    padding: 20px 30px 0 30px;

    h1 {
        font-size: 32px;
        text-align: center;
        margin-top: 30px;
    }

    .profile-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 10px;
        gap: 20px;
    }

    .profile-col {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-indent: 1px;

        span {
            font-weight: bold;
        }
    }

    .container-white {
        background-color: #fff;
        border-radius: 10px;
        margin-top: 10px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        text-align: left;
        padding-right: 220px;
        padding-left: 220px;
    }
}

@media only screen and (max-width: 1200px) {
    .profile {
        .profile-row {
            flex-direction: column;
            text-indent: 30%;
        }
    }
}

@media only screen and (max-width: 700px) {
    .profile {
        .profile-row {
            flex-direction: column;
            text-indent: 30%;

            button {
                width: 100%;
            }
        }

        div:first-child {
            flex-direction: column;
            align-items: start;

            * {
                margin-bottom: 1rem;
                width: 100%;
            }

            h1 {
                font-size: 1.7em;
            }
        }
    }
}

.profile-personal {
    p {
        font-size: 18px;
        font-weight: 400;
    }
    span {
        font-size: 20px;
        font-weight: 400;
    }
}
