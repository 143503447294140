.button {
  flex-shrink: 0;
  border-radius: 8px;
  background: #eff9f0;
  padding: 15px;
  /* Text Button CAEQ */
  font-size: 17px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 1.615px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8); 
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 
  cursor: pointer;
  transition: background-color 0.3s ease; 
  padding: 0.5rem 1.5rem;
  border: none;
}

.active {
    background-color: #eea300; 
    transform: translateY(-3px);
    color: rgb(255, 255, 255);
}

.button:hover {
    background-color: #e0e0e0; 
    transform: translateY(-3px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8); 
}

.button-navbar:active {
    color: #e0e0e0;
}

.button-.button-navbar:not(:active) {
    color: #000000;
}

.button-primary {
    background-color: #eea300;
    color: #ffffff;
}
.button-primary:hover {
    background-color: #fab82b; 
}

.button-cancel {
    background-color: #ffffff;
    color: #ab3428;
    border: solid #ab3428 2px;
}
.button-cancel:hover {
    background-color: #ffffff; 
}

.button-success {
    color: #eff9f0;
    background-color: #136f63;
}

.button-success:hover {
    background-color: #0f9c89; 
}

.button-fail {
    color: #eff9f0;
    background-color: #ab3428;
}

.button-fail:hover {
    background-color: #c73c2f; 
}

.button-disabled {
    color: #ffffff;
    background-color: #4d585e;
}

.button-disabled:hover {
    background-color: #6e7475; 
}


.button-navbar {
    background-color: #eff9f0;
    width: 200px;
    height: 45px;
    border-radius: 10px;
}

.button-navbar:hover {
    background-color: #fab82b; 
}


.button-content {
    text-align: center;
    flex-grow: 2;
}

.icon img {
    width: 24px;
    height: 24px;
}

.button-secondary {
    color: #ffffff;
    background-color: #062e52;
}

.button-secondary:hover {
    background-color: #062e52;
}
