.course-attendee-container {
    h2 {
        position: inline;
    }

    img {
        width: 70px;
        max-width: 70px;
        align-self: center;
    }

    td {
        padding: 0.5rem;

        img {
            margin-left: 50%;
            transform: translate(-50%, 0);
        }
    }

    .table-wrapper {
        max-height: 800px;
        overflow: auto;
        .tabla {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0 16px;
            .tabla th,
            .tabla td {
                padding: 24px;
                text-align: center;
                border-radius: 10px;
                white-space: nowrap;
            }
        }
    }

    .course-attendee-container-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h2 {
            display: inline-block;
        }

        @media screen and (max-width: 700px) {
            flex-direction: column;
        }
    }
}
