.accept-payment-container{
    display: flex;
    flex-direction: column;
    h1{
        text-align: center;
    }
    h3 {
        font-size: 24px;
        display: block;
        margin-left: 10px;
    }
    padding: 20px;  

    .payment-row-instruction{
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        img {
            width: 57px;
            height: 57px;
        }
    }
    
    .payment-cards{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        ;
        
    }
}