.containercaeq {
    .imagen-oscura{
        background-color: #000000;
    }
    .welcome {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: url('../../components/images/backgroundCAEQNew.png') center/cover
            no-repeat fixed;
        padding: 20px;
        min-height: 100vh;
        color: #000000;
        position: relative;

        .button {
            width: 200px;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
        }

        .adminbutton {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background-color: lightgray;
            margin-left: auto;
            margin-bottom: 30px;

            @media (max-width: 768px) {
                margin-left: 0;
            }
        }
    }
    .infocaeq {
        display: flex;
        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
        }
    }

    .titleOne,
    .titleTwo,
    .titleThree {
        font-weight: 700;
        width: 566px;
        height: 107px;
        font-size: 70px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 768px) {
            font-size: 2rem;
            text-align: center;
            width: 100%;
        }
    }

    .descOne,
    .descTwo,
    .descThree {
        flex-shrink: 0;
        text-align: center;
        font-size: 24px;
        line-height: normal;
        @media (max-width: 768px) {
            font-size: 1rem;
            text-align: center;
            width: 100%;
        }
    }

    .left-column,
    .right-column {
        flex: 1; // Distribute space equally between columns
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (max-width: 768px) {
            padding: 10px;
            text-align: center;
        }

        img {
            align-self: flex-start;
        }
    }

    .button-container {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
        }

        .button {
            margin-top: 2rem;
            margin-inline: 3rem;
        }

        div.solid {
            width: 100%;
            @media screen and (max-width: 768px) {
                margin-top: 20px;
                border-bottom: 5px solid #e4dede;
                width: 200px;
            }
        }
    }

    .logo {
        align-self: flex-start;
        max-width: 100%;
        margin-right: 20px;
        margin-bottom: 20px;

        @media (max-width: 1000px) {
            width: 300px;
            background-color: transparent;
        }
    }

    .titlecaeq {
        flex-shrink: 0;
        text-align: center;
        font-size: 48px;
        font-weight: 700;
        line-height: normal;

        @media (max-width: 768px) {
            font-size: 2em;
        }
    }

    .subtitlecaeq {
        flex-shrink: 0;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        line-height: normal;

        @media (max-width: 768px) {
            font-size: 1.5em;
        }
    }

    .image1,
    .image2,
    .image3 {
        max-width: 100%;
        height: auto;
        @media (max-width: 768px) {
            max-width: 100%;
        }
    }

    .values {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
        justify-content: center;
    }
}
