.gathering-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 550px;
    height: fit-content;
    width: 300px;
    border-radius: 15px;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
    padding: 1rem;

    .gathering-card--title {
        padding: 10px;
        width: 290px;
        font-size: 20px;
        font-weight: 800;
        overflow-wrap: break-word;
        word-wrap: break-word;
    }

    .image-more-info {
        height: 250px;
    }

    .gathering-card--row--icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        overflow-wrap: break-word;
        word-wrap: break-word;
        width: 200px;
        max-width: 100%;


        p {
            max-width: 100%;
            display: block;
            margin-left: 5px;
        }
    }
    .gathering-card--row--buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
    }

}
