.directory {
    padding: 20px 30px 0 30px;

    .directory-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .filter-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        gap: 4rem;
    }

    .inputText-filters {
        flex: 1;
        min-width: 150px;
    }

    .searchbars-column {
        flex: 1;
    }

    .dropdown-input {
        width: 350px;
        background-color: #eea300;
        color: white;
        margin-bottom: 20px;
    }

    .DropdownInputs-row {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        gap: 2rem;
    }

    .inputNumber-date-row {
        display: flex;
        flex-wrap: wrap;
        gap: 4.5rem;
    }

    .inputNumber-row,
    .DateInput-row {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
    }

    .inputNumber,
    .DateInput {
        flex: 1;
        max-width: 0.5rem;
    }

    .InputContainer,
    .DateInput-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .Input-title,
    .DateInput-title {
        margin-bottom: 10px;
        text-align: center;
    }

    .Input-row,
    .DateInput-row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .inputNumber-row h3,
    .DateInput-row h3 {
        text-align: center;
    }

    .box-container {
        border-radius: 8px;
        overflow: auto;
    }

    .directory-pagination {
        display: flex;
        justify-content: center;
        margin-top: 1.5rem;
    }

    .directory-main-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 2rem;
        margin-bottom: 2rem;
        align-items: center;
    }

    .no-data-message {
        color: red;
        font-weight: bold;
        font-size: 24px;
    }

    @media (max-width: 768px) {
        .directory-header {
            flex-direction: column;

            button {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }

    @media (max-width: 435px) {
        .directory-header {
            flex-direction: column;

            button {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }
}
