.reset-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url('../../components/images/backgroundCaeq.png') center/cover no-repeat;
    background-attachment: fixed;
    padding: 20px;
    height: 100vh;
    color: #000000; /* Color del texto dentro del contenedor */

    /* Estilos para el título */
    h2 {
      font-size: 24px;
      margin: 0;
      margin-bottom: 0px;
    }
  }
  
  .reset-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  
  .input-mail {
      fill: var(--ffffff-white, #FFF);
      stroke-width: 1.294px;
      stroke: var(--d-4-d-4-d-4-light-grey, #D4D4D4);
      filter: drop-shadow(0px 5.175946235656738px 5.175946235656738px rgba(0, 0, 0, 0.25)) drop-shadow(0px 5.175946235656738px 5.175946235656738px rgba(0, 0, 0, 0.25));
    }
  

  .reset-description {
    text-align: center;
    a {
        color: #136f63;
        text-decoration: none;
        font-weight: bold;
    }
  }
  
  .p2 {
    text-align: center;
    b {
        color: #136f63;
        text-decoration: none;
        font-weight: bold;
    }
  }
  