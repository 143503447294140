.room-card {
    display: block;
    border-radius: 15px;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
    width: 100%;

    .card-col-1 {
        display: grid;
        grid-template-columns: repeat(1, 3fr 2fr);
        align-items: center;

        h2 {
            align-items: center;
            justify-self: end;
        }

        p {
            justify-self: end;
            padding-right: 50px;
            color: green;
            font-weight: bold;
        }
    }

    .card-col-2 {
        a {
            pointer-events: none;
            cursor: default;
        }

        img {
            width: 100%;
            height: 345px;
            object-fit: cover;
        }
    }

    .card-col-3 {
        display: grid;
        grid-template-columns: repeat(1, 3fr 1fr);
        align-items: center;

        p {
            padding-left: 20px;
            font-weight: bold;
        }

        .card-button {
            padding: 20px;
            padding-right: 50px;
            display: flex;
            gap: 10px;
        }
    }

    .title-card {
        display: flex;
        justify-content: center;
    }

    .cost-card {
        display: flex;
        justify-content: end;
        align-items: center;
        color: green;
    }
}

@media (max-width: 768px) {
    .room-card {
        margin-top: 30px;
        padding-bottom: 10px;

        .card-col-1 {
            h2 {
                padding-right: 15px;
                justify-self: end;
            }
        }

        .card-col-3 {
            .card-button {
                padding-right: 55px;
            }
        }
    }
}

@media (max-width: 550px) {
    .room-card {
        .card-col-1 {
            h2 {
                padding-top: 10px;
                padding-left: 30px;
                justify-self: baseline;
            }

            p {
                justify-self: end;
                padding-right: 20px;
            }
        }

        .card-col-2 {
            img {
                width: 100%;
                height: 265px;
                object-fit: cover;
            }
        }

        .card-col-3 {
            display: inline;
            align-items: center;

            p {
                padding-left: 60px;
            }

            .card-button {
                padding: 10px;
                padding-right: 12px;
                display: grid;
            }
        }
    }
}
