.forgot-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('../../components/images/backgroundCaeq.png') center/cover no-repeat;
  background-attachment: fixed;
  padding: 20px;
  height: 100vh;
  color: #000000; /* Color del texto dentro del contenedor */
}

.forgot-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
form {
  display: flex;
  flex-direction: column;
}


.input-mail {
    fill: var(--ffffff-white, #FFF);
    stroke-width: 1.294px;
    stroke: var(--d-4-d-4-d-4-light-grey, #D4D4D4);
    filter: drop-shadow(0px 5.175946235656738px 5.175946235656738px rgba(0, 0, 0, 0.25)) drop-shadow(0px 5.175946235656738px 5.175946235656738px rgba(0, 0, 0, 0.25));
  }

.submit-mail {
    border-radius: 30px;
    background: #EEA300;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);  
}

/* Estilos para el título */
h2 {
  font-size: 24px;
  margin: 0;
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.forgot-description {
  text-align: center;
  margin: 20px auto; /* Añade margen superior e inferior y centra el contenido horizontalmente */
  max-width: 400px; /* Limita el ancho del contenedor para evitar que abarque toda la pantalla */
}

.p2 {
  text-align: center;
  b {
      color: #136f63;
      text-decoration: none;
      font-weight: bold;
  }
}
