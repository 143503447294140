.admin-card {
    border-radius: 20px;
    background: #fff;
    box-shadow: 4px 7px 15px 0px rgba(0, 0, 0, 0.35);
    padding: 10px;
    width: 400px;
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    height: 200px;

    .admin-card-buttons {
        align-self: end;
        height: 57px;
    }

    h2 {
        font-size: 30px;
    }
    h3 {
        font-size: 18px;
        margin-top: -5px;
        display: block;
        height: 100px;
    }
    img {
        margin-left: 5px;
        cursor: pointer;
    }
}
