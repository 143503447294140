.architect-detail {
    align-self: center;

    h1,
    h3 {
        width: 100%;
        text-align: center;
    }
    h2 {
        margin-top: 20px;
        width: 100%;
    }
    span {
        font-weight: bold;
        color: #eea300;
    }

    .architect-row {
        display: flex;
        width: 100%;
        justify-content: end;
        padding-right: 20px;
        padding-left: 20px;
        column-gap: 20px;
        .architect-col {
            p {
                padding-left: 20px;
            }
        }
        .button {
            margin-right: 20px;
            margin-bottom: 20px;
        }
    }

    .architect-col {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin-left: 0px;

        .architect-row {
            display: flex;
            flex-direction: row;
            width: 100%;
        }

        .label-input {
            margin-top: 10px;
        }

        .file-input {
            padding: 20px;
            width: 100%;
        }
    }

    @media screen and (max-width: 767px) {
        column-gap: 5px;
        display: flex;
        flex-direction: column;

        .architect-row {
            display: flex;
            flex-direction: column;
        }

        .architect-col {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-left: 0px;

            .file-input {
                width: 100%;
                height: 10%;
            }
        }
    }
    .list-data {
        font-size: 24px;
        width: 100%;
        text-align: center;
        .list-data-year {
            font-weight: 700;
        }
    }
}
