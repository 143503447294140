.dropdown-input {
    font-family: Montserrat;
    font-size: 18px;
    box-shadow: 4px 7px 15px 0px rgba(0, 0, 0, 0.35);
    width: 100%;
    height: 40px;
    letter-spacing: 1px;
    border-radius: 20px;
    padding: 0.5rem 1.5rem;
    border: none;
}

.dropdown-input:hover {
    cursor: pointer;
    transform: translateY(-3px); /* Elevación al pasar el ratón */
}