.directory {
    padding: 20px 30px 0 30px;

    .directory-row {
        justify-content: center;
        align-items: flex-start;
    }

    label {
        margin-right: 20px;
    }
    .InputText {
        max-width: 10px;
    }
    
    .box-container {
        border-radius: 8px;
        overflow: auto;
    }
    
    .directory-pagination {
        display: flex;
        justify-content: center;
        margin-top: 1.5rem;
    }
    
    .directory-header {
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
    }
    
    .no-data-message {
        color: red;
        font-style: bold;
        font-size: 24px;
    }
}


