.date-input {
    font-family: Montserrat;
    font-size: 18px;
    border-radius: 20px;
    box-shadow: 4px 7px 15px 0px rgba(0, 0, 0, 0.35);
    width: 100%;
    height: 40px;
    letter-spacing: 1px;
    padding-left: 12px;
    padding-right: 12px;
    border: none;
}