.course {
    padding: 20px 30px 0 30px;

    .course-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 0;
    }

    .course-col {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .course-details-header {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1rem;

        .course-details-row {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;

            div {
                display: flex;
                flex-direction: row;
                width: 25%;
                min-width: 200px;
                align-items: center;

                @media screen and (max-width: 450px) {
                    width: 100%;
                }

                p {
                    margin-left: 5px;
                    font-size: 16px;
                    font-weight: 400;
                }
                img {
                    width: auto;
                    height: 39px;
                }
            }
        }
    }

    .course-price {
        font-size: 2.5rem;
        color: #136f63;
        font-weight: 900;
        display: flex;
        flex-direction: row;
    }
    .course-price-2 {
        font-size: 2.5rem;
        color: #136f63;
        font-weight: 900;
        width: fit-content;
    }

    .course-time span {
        margin-right: 0.7rem;
    }

    .course-data img {
        margin-right: 0.5rem;
    }

    .course-review {
        max-width: 35vw;
    }

    .course-table {
        width: 100%;
        table-layout: fixed;

        td {
            align-items: center;
            display: flex;
            flex-direction: row;
            width: 25%;
            padding: 10px;
            p {
                margin: 0 0 0 10px;
            }
        }

        tr {
            display: flex;
            flex-direction: row;
        }

        img {
            align-self: center;
        }
    }

    .course-details {
        align-items: start;
        justify-content: stretch;

        img {
            width: 50%;
            margin-right: 1.5rem;
            border-radius: 20px;
        }

        p {
            font-size: 16px;
            font-weight: 300;
        }
    }

    .course-extras {
        justify-content: space-between;
        align-items: start;

        .course-col {
            margin-right: 1rem;

            p {
                font-size: 1rem;
                font-weight: normal;
            }
        }
    }

    .text-area {
        white-space: pre-line;
    }
}

@media only screen and (max-width: 700px) {
    .course {
        div:first-child {
            flex-direction: column;
            align-items: start;

            * {
                margin-bottom: 1rem;
                width: 100%;
            }

            h1 {
                font-size: 1.7em;
            }
        }

        .course-data {
            flex-wrap: wrap;

            .course-row {
                flex-direction: row !important;
            }

            img {
                max-width: 50px;
            }
        }

        .course-details {
            flex-direction: column;

            img {
                width: 100%;
            }

            .course-extras {
                flex-direction: column;
                width: 100%;
                margin: 0;
                margin-bottom: 0.5rem;
            }
        }
    }
}
