.container-white {
    border-radius: 20px;
    background: #fff;
    box-shadow: 4px 7px 15px 0px rgba(0, 0, 0, 0.35);
    padding: 10px;
    width: fit-content;
}

.gp-container {
    border-radius: 20px;
    background: #fff;
    box-shadow: 4px 7px 15px 0px rgba(0, 0, 0, 0.35);
    padding: 10px;
    width: 100%;
    height: 100%;
}
