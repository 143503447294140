.services {
    padding: 20px 30px 0 30px;

    .services-title {
        text-align: center;
        align-self: center;
    }

    .services-content {
        font-size: 16px;

        .services-subtitle {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .services-message {
            font-size: 25px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: right;
            width: 100%;
            margin-left: 20px;
            gap: 20px;
        }
    }

    .services-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: right;
        width: 100%;
        margin-left: 20px;
        gap: 20px;
    }

    .services-cards {
        width: 100%;
        padding-top: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
    }

    @media (max-width: 1024px) {
        .services-cards {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    @media (max-width: 768px) {
        .services-cards {
            display: inline-block;
        }
    }

    .services-pagination {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    @media screen and (max-width: 435px) {
        .services-content {
            .services-subtitle {
                flex-direction: column;

                button {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
        }

        .services-cards {
            display: inline-block;
        }
    }
}
