.tabla-container {
  width: 100%;
  overflow-x: auto;
}

.tabla {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 16px;
}

.table-wrapper {
  max-height: 800px;
}

.tabla th,
.tabla td {
  padding: 24px;
  text-align: left;
  border-radius: 10px;
  white-space: nowrap;
}

.fixed-header {
  position: sticky;
  top: 0;
  z-index: 2;
  /* Ajusta el valor según sea necesario */
}

.tabla thead {
  margin-top: 40px;
}

.restablecer-button {
  position: sticky;
}

.tabla th {
  font-size: 16px;
  font-weight: bold;
  background-color: #062e52;
  color: #fff;
  min-width: 200px;
  position: sticky;
  top: 0;
  z-index: 2;
}

.tabla td {
  border-bottom: 1px solid #ccc;
  z-index: 1;
}

.fila-sombrada {
  background-color: #f1f1f1;
  transition: background-color 0.5s, transform 0.3s;
  border-radius: 10px;
  margin-bottom: 8px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.fila-sombrada:hover {
  background-color: #f0f0f0;
  transform: translateY(-3px);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hide-button {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.hide-button-container {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}