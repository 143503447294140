.course-myinscriptions{ 
    padding: 3rem;

    .course-row { 
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 0;
    }

    .course-col { 
        display: flex;
        flex-direction: column;
    }

    .course-price {
        font-size: 1.5rem;
        color: #136F63;
        font-weight: 600;
    }

    .course-time span { 
        margin-right: 0.7rem;
    }

    .course-review {
        max-width: 35vw;
    }

    .course-data img {
        margin-right: 0.5rem;
    }

    .course-details { 
        align-items: start;
        justify-content: stretch;

        img {
            width: 50%;
            margin-right: 1.5rem;
            border-radius: 20px;
        }

        p {
            font-size: 1.5rem;
            font-weight: 600;
        }
    }

    .course-extras {
        justify-content: space-between;
        align-items: start;

        .course-col {
            margin-right: 1rem;

            p {
                font-size: 1rem;
                font-weight: normal;
            }
        }
    }

    .text-area {
        white-space: pre-line;
    }

}

@media only screen and (max-width: 700px)  {
    .course {
        padding: 0 1rem; 

        div:first-child {
            flex-direction: column;
            align-items: start;
            
            * {
                margin-bottom: 1rem;
                width: 100%;
            }

            h1{
                font-size: 1.7em;
            }
        }

        .course-data {
            flex-wrap: wrap;

            .course-row {
                flex-direction: row !important;
            }

            img {
                max-width: 50px;
            }
        }

        .course-details {
            flex-direction: column;

            img {
                width: 100%;
            }

            .course-extras {
                flex-direction: column;
                width: 100%;
                margin: 0;
                margin-bottom: 0.5rem;
            }
        }
    }

}
