.gatherings {
    padding: 20px 30px 0 30px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    @media screen and (max-width: 768px) {
        button{
            width: 100%;
        }
        
    }

    .gathering__section {
        display: flex;
        flex-direction: column;
    }
    
    .filter__section {
        margin-bottom: 20px; 
    }
    
    .gathering-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;

        h1{
            text-align: center;
        }
        
        button{
            width: auto;
        }
    }
    
    .gathering-pages {
        display: flex;
        gap: 1rem;
        justify-content: center;
        margin: 20px 0 20px 0;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            
        }

    }

    .gatherings_title--row{
        justify-content: center;
    }
    .create-gathering{
        justify-content:center;
    }
}

