.signup-admin-container {
    display: flex;
    flex-direction: column;
    background: rgba(169, 169, 169, 0.5); /* Background color with opacity */
    background-image: url('../../components/images/backgroundCaeqImage.png');
    background-position: 10% right center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100vh;
    height: fit-content;
    color: #000000;
    position: relative;

    @media (max-width: 1000px) {
        background-image: url('../../components/images/backgroundCaeq.png');
    }
    .signup-form {
        padding: 20px;
        background-color: white;
        width: 50%;
        min-height: 100vh;
        height: fit-content;

        @media (max-width: 1000px) {
            width: 100%;
            background-color: transparent;
        }
    }

    h3 {
        margin-bottom: 5px; /* Reduzca el espacio inferior de los títulos h3 */
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        label {
            margin-bottom: 20px;
        }
    }

    .Logo {
        width: 500px;
        height: auto;
        margin-bottom: 20px;

        @media (max-width: 1000px) {
            max-width: 500px;
            width: 100%;
            background-color: transparent;
        }
    }

    .button-container {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-bottom: 30px;

        .button {
            margin-top: 2rem;
        }
    }
}
