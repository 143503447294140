.courses {
    padding: 20px 30px 0px 30px;

    .courses--row {
        display: flex;
    }

    .courses__header {
        align-items: center;
        justify-content: space-between;

        button {
            margin-right: 1rem;
        }
    }

    .courses__filters {
        justify-content: space-between;
        margin-bottom: 2rem;
        align-items: end;

        .date-range-input {
            margin-right: 1rem;
        }

        .dropdown- {
            margin-right: 1rem;
            display: flex;
            align-items: end;
        }

        .dropdown-input {
            background-color: #eea300;
            color: white;
        }
    }

    .box-input {
        width: 25vw !important;
    }

    .courses__courses-section {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }

    .courses__courses-pagination {
        display: flex;
        justify-content: center;
        margin-top: 1.5rem;
    }

    @media screen and (max-width: 1200px) {
        .courses__filters {
            flex-wrap: wrap;
            align-items: start;

            .courses--row {
                flex-direction: column;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .courses__filters {
            flex-direction: column;

            .box-input {
                width: 100% !important;
            }

            .courses--row {
                flex-direction: column;

                .dropdown-input {
                    width: 100%;
                }
            }
        }

        .courses__header {
            flex-direction: column;

            div {
                display: flex;
                flex-direction: column;

                .button {
                    margin-bottom: 1rem;
                }
            }
        }
    }
}
