.h1-A {
    padding-left: 40px;
}

.signup-container {
    margin-bottom: 10px !important;
    display: flex;
    flex-direction: column;
    background: url('../../components/images/backgroundCAEQNew.png') right center/cover
        no-repeat;
    background-attachment: fixed;
    height: 100%;
    color: #000000; /* Color del texto dentro del contenedor */

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
    
        .column {
            margin: 20px;
            padding: 10px;
            display: flex;
            flex-direction: column;
        }
        .column-2 {
            margin: 20px;
            margin-top: 1px;
            padding-right: 10px;
            display: flex;
            flex-direction: column;
            
            .especialties-help-text{
                position: relative;
                color: var(--Gray-CAEQ, #4d585e);
                font-feature-settings: "clig" off, "liga" off;
                font-family: Montserrat;
                font-size: 24px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                letter-spacing: 1.68px;
                padding-left: 20px;
                margin-bottom: 25px;
                word-break: keep-all;

            }
        }
    }
 
    @media (max-width: 768px) {
        .grid-container {
            display: block;
        }
    }

    
    .Logo {
        padding-left: 10px;
        padding-top: 10px;
        width: 300px;
        height: auto;
        margin-bottom: 20px;
    }
    
    label {
        margin-bottom: 20px; /* Add margin to these input types */ 
        select {
            margin-bottom: 20px;
        }
    }

    .button-container {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 30px;
    }

}
