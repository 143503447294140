.navbar {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.padding-nav {
    display: none;

    @media screen and (max-width: 550px) {
        height: 70px;
        display: block;
    }
}

.navbar-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        height: 100px;

        @media screen and (max-width: 500px) {
            height: auto;
            width: 100%;
        }
    }
    @media screen and (max-width: 550px) {
        display: none;
    }
}

.navbar-center {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;

    button {
        flex-shrink: 1;
        width: 100%;
        max-width: 130px;
    }

    @media screen and (max-width: 1100px) {
        column-gap: 5px;

        button {
            font-size: 8px;
            min-width: 60px;
        }

        img {
            width: 20px;
        }
    }

    @media screen and (max-width: 550px) {
        display: none;
        column-gap: 5px;

        button {
            font-size: 8px;
            min-width: 60px;
        }

        img {
            width: 20px;
        }
    }
}

.navbar-button {
    margin-top: 10px;
    margin-right: 10px;
    align-self: flex-end;
    margin-bottom: 20px;

    @media screen and (max-width: 767px) {
        // Media query for screens smaller than 768px (phones)
        align-self: first baseline;
        row-gap: 10px;
        flex-direction: column;
    }
}
