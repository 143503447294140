.search-inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    gap: 5px;

    @media (max-width: 570px) {
        flex-direction: column;
    }

    label {
        width: 50%;
        @media (max-width: 570px) {
            width: 100%;
        }
    }
}

.attendee-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    button {
        max-width: 300px;
        width: 100%;
    }
}
