/* Position and sizing of burger button */
.topNav {
    display: none;
    z-index: 1;

    @media screen and (max-width: 550px) {
        display: block;
        position: fixed;
        width: 100vw;
        height: 70px;
        border-bottom: #373a47 3px solid;
        background-color: #eea300;
    }

    h2 {
        z-index: 1;
        position: fixed;
        top: 5px;
        width: fit-content;
        left: 85px;
        cursor: pointer;
        color: #ffffff;
    }

    .bm-burger-button {
        position: fixed;
        width: 150px;
        height: 30px;
        left: 36px;
        top: 20px;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
        background: #ffffff;
        width: 36px;
        height: 30px;
        left: 36px;
        top: 36px;
    }

    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
        background: #a90000;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
        height: 24px;
        width: 24px;
    }

    /* Color/shape of close button cross */
    .bm-cross {
        background: #bdc3c7;
    }

    /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
    .bm-menu-wrap {
        position: fixed;
        height: 100%;
    }

    /* General sidebar styles */
    .bm-menu {
        background: #373a47;
        padding: 2.5em 1.5em 0;
        font-size: 1.15em;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
        fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
        color: #b8b7ad;
        padding: 0px;
    }

    /* Individual item */
    .bm-item {
        display: inline-block;
    }

    /* Styling of overlay */
    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
    }

    .burguer-center {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        column-gap: 50px;

        button {
            flex-shrink: 1;
            width: 100%;
        }

        @media screen and (max-width: 468px) {
            column-gap: 5px;
            button {
                margin-top: 10px;
                font-size: auto;
                min-width: 60px;
            }

            img {
                width: 20px;
            }
        }
    }

    .burguer-button {
        margin-top: 10px;
        margin-right: 10px;
        align-self: flex-end;
        margin-bottom: 20px;

        button {
            width: 100%;
        }
    }
}
