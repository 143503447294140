/* CreatableSelect.scss */

.creatable-select {
  /* Agrega tus estilos personalizados aquí */
  font-size: 16px;
  padding: 8px;
  border-radius: 20px;
  box-shadow: 4px 7px 15px 0px rgba(0, 0, 0, 0.35);
  border-radius: 20px;

  div {
    border: none;
  }
}

.creatable-select:hover {
  cursor: pointer;
  transform: translateY(-3px); /* Elevación al pasar el ratón */
}
