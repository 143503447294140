* {
    font-family: Montserrat, sans-serif;
    box-sizing: border-box;
}
body {
    padding: 0px;
    margin: 0px;
}
a {
    text-decoration: none;
}
