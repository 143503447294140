.accept-admin {
    color: #000;
    text-align: center;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    flex-direction: column;
    width: 100%;

    padding: 20px 30px 50px 30px;

    h1 {
        font-size: 32px;
    }
    h3 {
        font-size: 24px;
        display: block;
        margin-left: 10px;
        word-break: break-word;
    }

    .instruction {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        img {
            width: 57px;
            height: 57px;
        }

        @media  screen and (max-width: 768px) {
            flex-direction: column-reverse;
            text-align: left;
            
        }
    }

    .admin-cards {
        margin-top: 2rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .registration-cards {
        margin-top: 2rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 30px;
    }
}
