/* LogingSignUp.scss */

/* Estilos para el contenedor principal */
.login-user-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url('../../components/images/backgroundCAEQNew.png') center/cover no-repeat;
    background-attachment: fixed;
    padding: 20px;
    min-height: 100vh;
    height: fit-content;
    color: #000000; /* Color del texto dentro del contenedor */

    /* Estilos para el logo */
    .Logo {
        width: 500px;
        height: auto;
        margin-bottom: 20px;

        @media (max-width: 1000px) {
            width: 300px;
            background-color: transparent;
        }
    }

    form {
        display: flex;
        flex-direction: column;
    }

    /* Estilos para el título */
    h2 {
        font-size: 24px;
        margin: 0;
        margin-bottom: 15px;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .forgot-register-links {
        text-align: center;
        a {
            color: #136f63;
            text-decoration: none;
            font-weight: bold;
        }
    }
}
